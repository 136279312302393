<template>
  <div class="primary">
    <br>
    <br>
    <v-container>
      <v-row class="white elevation-3 test">
        <v-col
          cols="12"
          md="6"
        >
          <base-section-heading
            align="left"
            subtitle="Mulligan Hospitality Ireland"
            tag="h2"
            title="our venues"
          >
            Five fantastic venues steeped in heritage & tradition located in Dublin.
          </base-section-heading>
          <base-btn
            to="venues"
            data-aos="flip-up"
          >
            VIEW VENUES
          </base-btn>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-img
            data-aos="zoom-in"
            data-aos-duration="1000"
            :src="require('@/assets/m&hss.jpg')"
            class="ml-auto elevation-3"
            height="400"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="white elevation-3 test">
        <v-col
          cols="12"
          md="6"
        >
          <base-section-heading
            align="left"
            subtitle="Mulligan Hospitality Ireland"
            tag="h2"
            title="our hotels"
          >
            Five fantastic venues steeped in heritage & tradition located in Dublin.
          </base-section-heading>
          <base-btn
            to=""
            data-aos="flip-up"
          >
            VIEW HOTELS
          </base-btn>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-img
            data-aos="zoom-in"
            data-aos-duration="1000"
            :src="require('@/assets/hotel.jpg')"
            class="ml-auto elevation-3"
            height="400"
          />
        </v-col>
      </v-row>
    </v-container>
    <br>
    <br>
  </div>
</template>

<style scoped>
.test {
  padding: 15px 15px 15px 15px;
  margin: 10px 10px 10px 10px;
}

</style>

<script>
  export default {
    name: 'Homesec',
  }
</script>
